import { createStore } from "vuex";
import Cookies from "js-cookie";

export default createStore({
  state: {
    darkMode: {
      isDark: Cookies.get("darkMode") === "true",
    },
    cookiePreferences: {
      hasInteracted: false,
      necessary: true,
      analytics: false,
      preferences: false,
    },
    cookieChoiceMade: sessionStorage.getItem("cookieChoice") === "true",
  },
  mutations: {
    toggleDarkMode(state) {
      state.darkMode.isDark = !state.darkMode.isDark;
      if (Cookies.get("cookiePreferences")) {
        Cookies.set("darkMode", state.darkMode.isDark, { expires: 365 });
      }
      document.documentElement.classList.toggle("dark-mode");
    },
    setCookiePreferences(state, preferences) {
      state.cookiePreferences = preferences;
      state.cookieChoiceMade = true;
      sessionStorage.setItem("cookieChoice", "true");
    },
  },
});
