<template>
  <div id="app" :class="{ 'dark-mode': isDark }">
    <nav>
      <router-link to="/">Home</router-link> |
      <router-link to="/projects">Projects</router-link> |
      <router-link to="/about">About</router-link> |
      <router-link to="/contact">Contact</router-link>
    </nav>
    <button class="theme-toggle" @click="toggleDarkMode">
      {{ isDark ? "☀️" : "🌙" }}
    </button>
    <div class="router-view-container">
      <router-view />
    </div>
    <footer class="site-footer">
      <p>© {{ currentYear }} Maji Development. All rights reserved.</p>
    </footer>
    <div v-if="!cookiesAccepted" class="cookie-banner">
      <div class="cookie-content">
        <p>
          We use cookies to enhance your experience. You can customize your
          preferences or accept all cookies.
        </p>
        <div class="cookie-buttons">
          <button @click="acceptAllCookies" class="accept-btn">
            Accept All
          </button>
          <button @click="rejectAllCookies" class="reject-btn">
            Reject All
          </button>
          <button @click="showCookieSettings" class="settings-btn">
            Customize
          </button>
        </div>
      </div>
    </div>

    <div v-if="showSettings" class="cookie-settings-modal">
      <div class="modal-content">
        <h3>Cookie Preferences</h3>
        <div class="cookie-options">
          <div class="cookie-option">
            <label>
              <input
                type="checkbox"
                v-model="cookiePreferences.necessary"
                disabled
                checked
              />
              Necessary (Required)
            </label>
            <p>Essential for website functionality</p>
          </div>
          <div class="cookie-option">
            <label>
              <input type="checkbox" v-model="cookiePreferences.analytics" />
              Analytics
            </label>
            <p>Help us improve our website</p>
          </div>
          <div class="cookie-option">
            <label>
              <input type="checkbox" v-model="cookiePreferences.preferences" />
              Preferences
            </label>
            <p>Remember your settings</p>
          </div>
        </div>
        <div class="modal-buttons">
          <button @click="saveCookiePreferences" class="save-btn">
            Save Preferences
          </button>
          <button @click="closeSettings" class="cancel-btn">Cancel</button>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
:root {
  --bg-color: #ffffff;
  --text-color: #2c3e50;
  --link-color: #2c3e50;
  --active-link: #42b983;
  --card-bg: #ffffff;
  --border-color: #dddddd;
  --nav-bg: #ffffff;
  --input-bg: #ffffff;
  --modal-bg: #ffffff;
  --hover-bg: #f5f5f5;
  --scrollbar-bg: #f5f5f5;
  --scrollbar-thumb: #42b983;
  --scrollbar-thumb-hover: #3aa876;
}

.dark-mode {
  --bg-color: #1a1a1a;
  --text-color: #ffffff;
  --link-color: #e0e0e0;
  --active-link: #42b983;
  --card-bg: #2d2d2d;
  --border-color: #404040;
  --nav-bg: #2d2d2d;
  --input-bg: #333333;
  --modal-bg: #2d2d2d;
  --hover-bg: #363636;
  --scrollbar-bg: #2d2d2d;
  --scrollbar-thumb: #42b983;
  --scrollbar-thumb-hover: #3aa876;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: var(--scrollbar-bg);
  border-radius: 5px;
}

::-webkit-scrollbar-thumb {
  background: var(--scrollbar-thumb);
  border-radius: 5px;
  transition: background 0.3s ease;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--scrollbar-thumb-hover);
}

* {
  scrollbar-width: thin;
  scrollbar-color: var(--scrollbar-thumb) var(--scrollbar-bg);
}

body {
  margin: 0;
  padding: 0;
  background-color: var(--bg-color);
  transition: background-color 0.3s ease;
  overflow-y: scroll !important;
}

#app {
  font-family: "Arial", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: var(--text-color);
  min-height: 100vh;
  background-color: var(--bg-color);
}

.site-footer {
  padding: 20px;
  background: var(--bg-color);
  color: var(--text-color);
  border-top: 1px solid var(--border-color);
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: var(--link-color);
    text-decoration: none;

    &.router-link-exact-active {
      color: var(--active-link);
    }
  }
}

nav {
  padding: 30px;
  position: sticky;
  top: 0;
  background: var(--bg-color);
  z-index: 100;
}

.theme-toggle {
  position: fixed;
  top: 1rem;
  right: 1rem;
  z-index: 1000;
  padding: 0.5rem;
  font-size: 1.5rem;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  background: var(--card-bg);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.1);
  }
}

.cookie-banner {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: var(--card-bg);
  padding: 1.5rem;
  border-top: 1px solid var(--border-color);
  z-index: 1000;

  .cookie-content {
    max-width: 1200px;
    margin: 0 auto;
  }

  .cookie-buttons {
    display: flex;
    gap: 1rem;
    margin-top: 1rem;
    justify-content: center;

    button {
      padding: 0.75rem 1.5rem;
      border-radius: 4px;
      border: none;
      cursor: pointer;
      font-weight: bold;
    }

    .accept-btn {
      background: #42b983;
      color: white;
    }

    .reject-btn {
      background: #ff4757;
      color: white;
    }

    .settings-btn {
      background: var(--hover-bg);
      color: var(--text-color);
    }
  }
}

.cookie-settings-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1001;

  .modal-content {
    background: var(--card-bg);
    padding: 2rem;
    border-radius: 8px;
    max-width: 500px;
    width: 90%;
  }

  .cookie-options {
    margin: 1.5rem 0;

    .cookie-option {
      margin-bottom: 1rem;
      text-align: left;

      label {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        font-weight: bold;
      }

      p {
        margin: 0.25rem 0 0 1.5rem;
        font-size: 0.9rem;
        opacity: 0.8;
      }
    }
  }
}
</style>

<script>
import { mapState } from "vuex";
import Cookies from "js-cookie";

export default {
  data() {
    return {
      currentYear: new Date().getFullYear(),
      showSettings: false,
      cookiePreferences: {
        necessary: true,
        analytics: false,
        preferences: false,
      },
    };
  },
  computed: {
    ...mapState({
      isDark: (state) => state.darkMode.isDark,
    }),
    cookiesAccepted() {
      return this.$store.state.cookieChoiceMade;
    },
  },
  methods: {
    toggleDarkMode() {
      this.$store.commit("toggleDarkMode");
    },
    acceptAllCookies() {
      this.cookiePreferences = {
        necessary: true,
        analytics: true,
        preferences: true,
      };
      this.saveCookiePreferences();
    },
    rejectAllCookies() {
      Cookies.remove("cookiePreferences");
      Cookies.remove("darkMode");

      this.cookiePreferences = {
        necessary: true,
        analytics: false,
        preferences: false,
      };

      this.$store.commit("setCookiePreferences", this.cookiePreferences);

      localStorage.setItem("cookiesRejected", "true");
    },
    showCookieSettings() {
      this.showSettings = true;
    },
    closeSettings() {
      this.showSettings = false;
    },
    saveCookiePreferences() {
      Cookies.set("cookiePreferences", this.cookiePreferences, {
        expires: 365,
      });
      this.$store.commit("setCookiePreferences", this.cookiePreferences);
      this.showSettings = false;
    },
  },
  mounted() {
    if (this.isDark) {
      document.documentElement.classList.add("dark-mode");
    }
  },
};
</script>
